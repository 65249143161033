<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="titlePage" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog v-model="paidModal" persistent max-width="500">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title class="overline">Baixar Parcela</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="closePaidModal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container fluid>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    outlined
                    v-model="payed.accountId"
                    :items="accounts"
                    item-value="id"
                    item-text="name"
                    label="Conta"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="mt-n8">
                  <v-select
                    outlined
                    v-model="payed.paymentMethodId"
                    :items="paymentMethods"
                    item-value="id"
                    item-text="title"
                    label="Forma Pagamento"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="mt-n8">
                  <span>Total Pago</span>
                  <money
                    class="money"
                    v-model="payed.amount"
                    label="Total Pago"
                    v-bind="money"
                  ></money>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="payed.username"
                    label="Usuário"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="payed.password"
                    label="Senha"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n5">
                  <v-btn dark color="green" width="100%" @click="updatePaid">
                    BAIXAR
                  </v-btn>
                </v-col>
                <v-col cols="12" class="mt-n4">
                  <v-btn dark color="red" width="100%" @click="closePaidModal">
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="140" class="align-center">
          <v-row>
            <v-col cols="3" class="mt-6">
              <v-autocomplete
                outlined
                v-model="supplierId"
                :items="suppliers"
                item-value="id"
                item-text="person.corporateName"
                label="Fornecedor"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="mt-6">
              <v-text-field
                outlined
                v-model="startDate"
                type="date"
                label="Data Inicial"
              ></v-text-field>
            </v-col>

            <v-col cols="2" class="mt-6">
              <v-text-field
                outlined
                v-model="endDate"
                type="date"
                label="Data Final"
              ></v-text-field>
            </v-col>
            <v-col cols="1" />
            <v-col cols="2" class="mt-6">
              <v-radio-group v-model="isPaid" @change="changeFilter()">
                <v-radio label="Pagos" :value="1"></v-radio>
                <v-radio label="Não Pagos" :value="0"></v-radio>
                <v-radio label="Todos" :value="2"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2" class="mt-9">
              <v-btn dark color="green" outlined @click="getItems()">
                BUSCAR
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="payables"
          :search="search"
          hide-default-footer
          disable-pagination
          :item-class="itemRowBackground"
        >
          <template v-slot:[`item.amount`]="{ item }">
            <span>R$ {{ formatPrice(item.amount, item.type) }}</span>
          </template>

          <template v-slot:[`item.number`]="{ item }">
            <span>{{ `${item.frequencyNumber}/${item.frequencyTotal}` }}</span>
          </template>
          <template v-slot:[`item.dueDate`]="{ item }">
            <span>{{ dateFormat(item.dueDate) }}</span>
          </template>
          <template v-slot:[`item.paidDate`]="{ item }">
            <span>{{ item.paidDate ? dateFormat(item.paidDate) : "" }}</span>
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <v-btn
              x-small
              color="success"
              v-if="!item.paid"
              @click="openPaidModal(item)"
              >Baixar
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <br />
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Por Classificação</v-toolbar-title>
        </v-toolbar>
        <v-toolbar-items
          class="elevation-2"
          v-for="(debit, ic) in debits"
          :key="ic"
        >
          <v-row
            class="d-flex justify-space-between"
            style="
              padding-left: 20px !important;
              padding-right: 20px !important;
            "
          >
            <v-card-subtitle>{{ debit.name }} </v-card-subtitle>
            <v-card-subtitle
              >R$ {{ formatPrice(debit.amount, "C") }}</v-card-subtitle
            >
          </v-row>
        </v-toolbar-items>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-toolbar-items class="elevation-2 orange">
              <v-row
                class="d-flex justify-space-between"
                style="
                  padding-left: 20px !important;
                  padding-right: 20px !important;
                "
              >
                <v-card-title class="overline text-white">Aberto</v-card-title>
                <v-card-title class="overline text-h6 text-white"
                  >R$ {{ formatPrice(totalUnpaid, "C") }}</v-card-title
                >
              </v-row>
            </v-toolbar-items>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-toolbar-items class="elevation-2 green">
              <v-row
                class="d-flex justify-space-between"
                style="
                  padding-left: 20px !important;
                  padding-right: 20px !important;
                "
              >
                <v-card-title class="overline text-white">Pago</v-card-title>
                <v-card-title class="overline text-h6 text-white"
                  >R$ {{ formatPrice(totalPaid, "C") }}</v-card-title
                >
              </v-row>
            </v-toolbar-items>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-toolbar-items class="elevation-2 primary">
              <v-row
                class="d-flex justify-space-between"
                style="
                  padding-left: 20px !important;
                  padding-right: 20px !important;
                "
              >
                <v-card-title class="overline text-white">Total</v-card-title>
                <v-card-title class="overline text-h6 text-white"
                  >R$ {{ formatPrice(amountTransactions, "C") }}</v-card-title
                >
              </v-row>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import { Money } from "v-money";
export default Vue.extend({
  title: "FinancialMovement",
  components: { PageHeader, InternalMenu, Money },
  data: () => ({
    titlePage: "Contas a Pagar",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Classificações Financeiras",
            to: "/finance/categories",
            permission: "finance.categories.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Métodos de Pagamento",
            to: "/finance/payment-methods",
            permission: "finance.payment_methods.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Extrato de Contas",
            to: "/finance/account-reports",
            permission: "finance.payment_methods.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Transações",
        disabled: false,
        href: "/finance/movements",
      },
      {
        text: "Contas a Pagar",
        disabled: true,
        href: "/finance/movements",
      },
    ],
    accounts: [],
    payables: [],
    suppliers: [],
    paymentMethods: [],
    totalPaid: 0.0,
    totalUnpaid: 0.0,
    amountTransactions: 0.0,
    credits: [],
    debits: [],
    searchReport: true,
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    id: "",
    accountId: null,
    supplierId: null,
    startDate: null,
    endDate: null,
    isPaid: 0,
    openDialog: false,
    paidModal: false,
    payed: {
      accountId: "",
      paymentMethodId: "",
      amount: "",
      username: "",
      password: "",
    },
    headers: [
      {
        text: "Nº",
        align: "start",
        value: "number",
        filtering: true,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Descrição",
        align: "start",
        value: "title",
        filtering: true,
      },
      {
        text: "Data Pagamento",
        align: "start",
        value: "paidDate",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        align: "start",
        value: "paymentMethod.title",
        filtering: true,
      },
      {
        text: "Valor R$",
        align: "start",
        value: "amount",
        filtering: true,
      },
      { text: "Baixar", value: "paid" },
    ],
  }),
  methods: {
    itemRowBackground: function (item) {
      const now = moment().format("YYYY-MM-DD");
      const dueDate = moment(item.dueDate).format("YYYY-MM-DD");

      if (item.paid) return "none";
      if (dueDate < now) return "style-2";
      if (dueDate == now) return "style-1";
      if (dueDate > now) return "none";
    },
    changeFilter() {
      if ((this.startDate && this.endDate) || this.supplierId) {
        this.getItems();
      }
    },
    openPaidModal(item) {
      this.id = item.id;
      this.payed.accountId = item.accountId;
      this.payed.amount = item.amount;
      this.payed.paymentMethodId = item.paymentMethodId;
      this.paidModal = true;
    },
    closePaidModal(item) {
      this.payed.accountId = "";
      this.payed.amount = "";
      this.payed.paymentMethodId = "";
      this.paidModal = false;
    },
    updatePaid() {
      http
        .post(`finance/transactions/${this.id}/register-payment`, this.payed)
        .then(
          () => {
            this.snackbar.text = "Transação Atualizada";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.paidModal = false;
            this.getItems();
          },
          () => {
            this.snackbar.text = "Erro ao Atualizar Transação";
            this.snackbar.color = "red";
            this.snackbar.opened = true;
          }
        );
    },
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return type === "C"
        ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : "-" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getItems() {
      this.loading = true;
      this.totalUnpaid = 0.0;
      this.totalPaid = 0.0;
      this.amountTransactions = 0.0;
      this.headers = [
        {
          text: "Nº",
          align: "start",
          value: "number",
          filtering: true,
        },
        {
          text: "Vencimento",
          align: "start",
          value: "dueDate",
          filtering: true,
        },
        {
          text: "Descrição",
          align: "start",
          value: "title",
          filtering: true,
        },
        {
          text: "Data Pagamento",
          align: "start",
          value: "paidDate",
          filtering: true,
        },
        {
          text: "Forma Pagamento",
          align: "start",
          value: "paymentMethod.title",
          filtering: true,
        },
        {
          text: "Valor R$",
          align: "start",
          value: "amount",
          filtering: true,
        },
        { text: "Baixar", value: "paid" },
      ];

      if ((!this.startDate || !this.endDate) && !this.supplierId) {
        this.snackbar.text = "Selecione ao menos o Fornecedor ou Período";
        this.snackbar.color = "black";
        this.snackbar.opened = true;
        this.loading = false;
        return;
      }
      let url = `results/transactions/unpaid?active=true`;
      let balanceUrl = `results/balance/payables?active=true`;
      if (this.isPaid === 1 || this.isPaid === 0) {
        url = `${url}&paid=${this.isPaid}`;
        balanceUrl = `${balanceUrl}&paid=${this.isPaid}`;
      }

      if (this.startDate && this.endDate) {
        url = `${url}&startDate=${this.startDate}&endDate=${this.endDate}`;
        balanceUrl = `${balanceUrl}&startDate=${this.startDate}&endDate=${this.endDate}`;
      }

      if (this.accountId) {
        url = `${url}&ccountId=${this.accountId}`;
        balanceUrl = `${balanceUrl}&ccountId=${this.accountId}`;
      }

      if (this.supplierId) {
        url = `${url}&supplierId=${this.supplierId}`;
        balanceUrl = `${balanceUrl}&supplierId=${this.supplierId}`;
      }
      await http.get(url).then(async (data) => {
        this.payables = data.data.payables;
        this.credits = data.data.credits;
        this.debits = data.data.debits;
        if (this.payables.length) {
          await http.get(balanceUrl).then((data) => {
            this.totalUnpaid = data.data.unpaid;
            this.totalPaid = data.data.paid;
            this.amountTransactions = data.data.balance;
            this.loading = false;
          });
        }
        if (!this.supplierId) {
          this.headers = [
            {
              text: "Nº",
              align: "start",
              value: "number",
              filtering: true,
            },
            {
              text: "Vencimento",
              align: "start",
              value: "dueDate",
              filtering: true,
            },
            {
              text: "Fornecedor",
              align: "start",
              value: "supplier.person.corporateName",
              filtering: true,
            },
            {
              text: "Descrição",
              align: "start",
              value: "title",
              filtering: true,
            },
            {
              text: "Data Pagamento",
              align: "start",
              value: "paidDate",
              filtering: true,
            },
            {
              text: "Forma Pagamento",
              align: "start",
              value: "paymentMethod.title",
              filtering: true,
            },
            {
              text: "Valor R$",
              align: "center",
              value: "amount",
              filtering: true,
            },
          ];
        } else {
          this.headers = [
            {
              text: "Nº",
              align: "start",
              value: "number",
              filtering: true,
            },
            {
              text: "Vencimento",
              align: "start",
              value: "dueDate",
              filtering: true,
            },
            {
              text: "Descrição",
              align: "start",
              value: "title",
              filtering: true,
            },
            {
              text: "Data Pagamento",
              align: "start",
              value: "paidDate",
              filtering: true,
            },
            {
              text: "Forma Pagamento",
              align: "start",
              value: "paymentMethod.title",
              filtering: true,
            },
            {
              text: "Valor R$",
              align: "start",
              value: "amount",
              filtering: true,
            },
            { text: "Baixar", value: "paid" },
          ];
        }
        this.loading = false;
      });
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then((data) => {
        this.accounts = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
    getPayments() {
      this.loading = true;
      http.get("finance/payment-methods").then((data) => {
        this.paymentMethods = data.data;
        this.loading = false;
      });
      this.loading = false;
    },

    getClients() {
      this.loading = true;
      http.get("base/suppliers").then((data) => {
        this.suppliers = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
  },
  async mounted() {
    await this.getAccounts();
    this.getPayments();
    this.getClients();
  },
});
</script>

<style>
.style-1 {
  background-color: rgb(215, 161, 44);
  color: white;
}
.style-2 {
  background-color: rgb(216, 0, 0);
  color: white;
}

.style-1:hover {
  background-color: rgb(255, 174, 0) !important;
  color: white;
}

.style-3 {
  background-color: rgb(120, 122, 146);
  color: white;
}
.style-2:hover {
  background-color: rgb(95, 0, 0) !important;
  color: rgb(255, 255, 255);
}
.text-white {
  color: white;
}
.money {
  background-color: rgb(0, 126, 0);
  height: 57px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>